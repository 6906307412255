import { Spring, animated, config } from 'react-spring';

import IndexLayout from '../components/index-layout';
import React from 'react';
import SEO from '../components/seo';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  componentDidMount() {
    this.setState({ show: true });
  }

  render() {
    const { location } = this.props;
    return (
      <IndexLayout location={location}>
        <SEO title="Home" keywords={[]} />
        <div className="flex flex-col max-w-sm text-white mt-12 md:mt-20 px-2">
          <Spring
            native
            config={{ ...config.slow, delay: 500 }}
            from={{ opacity: 0, transform: 'translateY(-30px)' }}
            to={{ opacity: 1, transform: 'translateY(0px)' }}
          >
            {props => (
              <animated.h1
                className="self-center text-5xl md:text-6xl font-serif font-normal text-white whitespace-no-wrap"
                style={props}
              >
                Pur &middot; vey &middot; or
              </animated.h1>
            )}
          </Spring>

          <div className="flex flex-col mt-1 mb-12">
            <Spring
              native
              config={{ ...config.slow, delay: 500 }}
              from={{ opacity: 0, transform: 'translateX(-20px)' }}
              to={{ opacity: 1, transform: 'translateX(0px)' }}
            >
              {props => (
                <animated.div
                  className="text-gold text-xs md:text-sm lg:text-base font-serif mb-2 md:pl-2"
                  style={props}
                >
                  &middot; Noun &middot;
                </animated.div>
              )}
            </Spring>
            <Spring
              native
              config={{ ...config.slow, delay: 500 }}
              from={{ opacity: 0, transform: 'translateY(20px)' }}
              to={{ opacity: 1, transform: 'translateY(0px)' }}
            >
              {props => (
                <animated.div
                  className="text-grey-lighter text-base md:text-2xl font-serif text-center"
                  style={props}
                >
                  A provider of particularly high-end fare, provisions &amp;
                  merriment
                </animated.div>
              )}
            </Spring>
          </div>

          <div class="flex flex-col md:flex-row justify-center">
            <Spring
              native
              config={{ ...config.slow, delay: 1100 }}
              from={{ opacity: 0, transform: 'translateX(20px)' }}
              to={{ opacity: 1, transform: 'translateX(0px)' }}
            >
              {props => (
                <animated.button
                  class="bg-transparent hover:bg-gold-dark text-grey-lighter border border-gold hover:border-gold-dark mb-6 md:mb-0 mx-auto md:ml-0 w-full md:mr-4 md:w-48 px-4 py-3"
                  style={props}
                >
                  Make a reservation
                </animated.button>
              )}
            </Spring>

            <Spring
              native
              config={{ ...config.slow, delay: 1300 }}
              from={{ opacity: 0, transform: 'translateX(20px)' }}
              to={{ opacity: 1, transform: 'translateX(0px)' }}
            >
              {props => (
                <animated.button
                  class="bg-transparent hover:bg-gold-dark text-grey-lighter border border-gold hover:border-gold-dark mx-auto md:mr-0 md:ml-4 w-full md:w-48 px-4 py-3"
                  style={props}
                >
                  See our menu
                </animated.button>
              )}
            </Spring>
          </div>
        </div>
      </IndexLayout>
    );
  }
}
