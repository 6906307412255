import { Spring, Trail, animated, config } from 'react-spring';

import React from 'react';
import socialLinks from '../data/social-links';

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <footer className="relative bg-black">
        <div className="flex flex-col-reverse md:flex-row items-center justify-between container mx-auto p-4 text-sm text-grey-darker">
          <div className="md:self-end flex flex-col items-center md:items-start mt-6 md:mt-0">
            <small>&copy; 2019, Church Street Purveyor</small>
            <small>
              Design by{' '}
              <a
                href="https://www.spadesparrow.com"
                className="no-underline text-gold-dark font-serif"
              >
                Spade &amp; Sparrow
              </a>
            </small>
          </div>

          <div className="w-24">
            <div className="font-sm text-center mb-1">Follow Us</div>
            <ul className="flex justify-between list-reset m-0">
              {socialLinks.map(item => (
                <li className="m-0">
                  <a href={item.link}>
                    <svg
                      aria-label={item.dataIcon}
                      alt={item.dataIcon}
                      data-prefix="fab"
                      data-icon={item.dataIcon}
                      className="fill-current text-grey-darker hover:text-gold inline-block h-5"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox={item.viewBox}
                    >
                      <path fill="currentColor" d={item.path} />
                    </svg>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}
