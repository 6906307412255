import { Spring, Trail, Transition, animated, config } from 'react-spring';

import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Link } from 'gatsby';
import React from 'react';
import navLinks from '../data/nav-links';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSlideNav: false
    };
    this.toggleNav = this.toggleNav.bind(this);
  }

  isHome = () => {
    return this.props.location && this.props.location.pathname === '/';
  };

  toggleNav = event => {
    event.preventDefault();

    this.setState({
      showSlideNav: !this.state.showSlideNav
    });
  };

  render() {
    return (
      <nav>
        <div className="flex items-center justify-between container text-white mx-auto p-4">
          <Spring
            config={{
              ...config.gentle,
              delay: 2000
            }}
            immediate={!this.isHome()}
            from={{ opacity: 0, transform: 'translateX(-20px)' }}
            to={{ opacity: 1, transform: 'translateX(0)' }}
          >
            {props => (
              <AniLink
                fade
                to="/"
                id="logoLink"
                className={`no-underline text-white text-xl md:text-2xl font-serif`}
                style={props}
              >
                Purveyor
              </AniLink>
            )}
          </Spring>

          <Spring
            config={{
              ...config.gentle,
              delay: 2000
            }}
            immediate={!this.isHome()}
            from={{ opacity: 0, transform: 'translateX(20px)' }}
            to={{ opacity: 1, transform: 'translateX(0)' }}
          >
            {props => (
              <button
                id="navButton"
                className={`block md:hidden flex items-center text-white z-30`}
                style={props}
                onClick={this.toggleNav}
              >
                <svg
                  className="fill-current h-4 w-4"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            )}
          </Spring>

          <Transition
            native
            items={this.state.showSlideNav}
            config={{ ...config.default }}
            from={{ right: '-100%' }}
            enter={{ right: '0px' }}
            leave={{ right: '-100%' }}
          >
            {show =>
              show &&
              (props => (
                <animated.div
                  id="slide-nav"
                  className="fixed pin-y pin-r flex flex-col items-start bg-black pb-8 pt-12 pl-6 overflow-hidden w-2/3 z-10"
                  style={props}
                >
                  <Trail
                    config={{ ...config.gentle, delay: 800 }}
                    items={navLinks}
                    keys={item => item.link}
                    from={{ opacity: 0, transform: 'translate3d(0, -20px, 0)' }}
                    to={{ opacity: 1, transform: 'translate3d(0, 0px, 0)' }}
                  >
                    {item => props => (
                      <AniLink
                        fade
                        to={item.link}
                        className={`block no-underline text-lg text-white hover:text-gold mt-3 pl-3 ${
                          this.props.location.pathname === item.link
                            ? 'border-l-2 border-gold'
                            : 'p-slide-nav-not-active'
                        }`}
                        style={props}
                      >
                        {item.text}
                      </AniLink>
                    )}
                  </Trail>
                </animated.div>
              ))
            }
          </Transition>

          <div
            id="nav"
            className="hidden md:flex md:items-center md:w-auto text-sm"
          >
            <Trail
              config={{ ...config.gentle, delay: 2000 }}
              items={navLinks}
              keys={item => item.link}
              immediate={!this.isHome()}
              from={{ opacity: 0, transform: 'translate3d(0, -20px, 0)' }}
              to={{ opacity: 1, transform: 'translate3d(0, 0px, 0)' }}
            >
              {item => props => (
                <AniLink
                  fade
                  to={item.link}
                  className={`inline-block no-underline text-base text-white hover:text-gold ml-3 px-2 ${
                    this.props.location &&
                    this.props.location.pathname &&
                    this.props.location.pathname === item.link
                      ? 'border-b border-gold'
                      : 'pb-px'
                  }`}
                  style={props}
                >
                  {item.text}
                </AniLink>
              )}
            </Trail>
          </div>
        </div>
      </nav>
    );
  }
}
