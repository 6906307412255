export default [
  {
    link: '/#',
    text: 'About'
  },
  {
    link: '/#',
    text: 'Menu'
  },
  {
    link: '/#',
    text: 'Contact'
  }
];
